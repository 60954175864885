<template>
  <div class="settings__container">
    <!-- <div class="settings__header__wrapper">
      <h1 class="sr-only">{{ name }}</h1>
      <h1 class="settings__header">
        {{ name }}
      </h1>
    </div> -->
    <header class="flex justify-between -mt-2">
      <div class="w-full">
        <h1 class="sr-only">Settings</h1>
        <h1 class="text-2xl leading-7 tracking-tight font-semibold text-gray-800 sm:text-2xl sm:truncate">Settings</h1>
        <p class="text-sm font-normal text-gray-600 w-full my-2">
          Adjust global settings including enabling and disabling protection organization-wide, user-initiated offline failsafe, send anyway timeout, and footer text.
        </p>
      </div>
    </header>

    <div class="settings__form__wrapper">
      <!-- Enable Preava Prevent -->
      <SwitchGroup>
        <div class="settings__switch__wrapper">
          <!--
            [NOTE]:
            The long list of classes found for Switch can't be moved to settings.css.
            It needs to be inlined otherwise it will cause a bug with the switch not
            properly working. Please keep the Switch classes here.
            - by @johnnypreavacom
          -->
          <Switch
            v-model="isPreventEnabled"
            :disabled="isWaiting"
            :class="[
              isPreventEnabled ? 'bg-blue-600' : 'bg-gray-300',
              'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
            ]"
          >
            <span :class="[isPreventEnabled ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
          </Switch>
          <SwitchLabel class="settings__label"> Scan outbound emails for risks </SwitchLabel>
        </div>
      </SwitchGroup>

      <!-- Authorization Domain -->

      <div>
        <div class="settings__section">
          <label for="authdomain" class="settings__section__header"> Authentication Domains </label>

          <DefaultTooltip message="All your company's authentication domains configured on the Preava IAM systems." />
        </div>

        <div v-if="tenant.domainsAuthenticaion">
          <div v-for="domain in tenant.domainsAuthenticaion.valuesList" :key="domain.value">
            <input
              v-model="domain.value"
              type="text"
              :name="`authdomain-${domain.value}`"
              :id="`authdomain-${domain.value}`"
              autocomplete="off"
              disabled
              aria-invalid="true"
              :aria-describedby="`authdomain-${domain.value}`"
              class="mt-2 relative shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-gray-100 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
            />
          </div>
        </div>
        <div v-else>
          <input
            type="text"
            name="authdomain"
            id="authdomain"
            autocomplete="off"
            disabled
            placeholder="loading..."
            aria-invalid="true"
            aria-describedby="authdomain"
            class="mt-1 relative rounded-md shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-gray-100 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
          />
        </div>
      </div>

      <!-- Internal Domain -->

      <div>
        <div class="settings__section">
          <label for="internaldomain" class="settings__section__header"> Internal Domains </label>

          <DefaultTooltip message="All your company's internal domains used for sending emails." />
        </div>

        <div v-if="tenant.domainsCommunication">
          <div v-for="domain in tenant.domainsCommunication.valuesList" :key="domain.value">
            <input
              v-model="domain.value"
              type="text"
              :name="`internaldomain-${domain.value}`"
              :id="`internaldomain-${domain.value}`"
              autocomplete="off"
              disabled
              aria-invalid="true"
              :aria-describedby="`internaldomain-${domain.value}`"
              class="mt-2 relative shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-gray-100 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
            />
          </div>
        </div>
        <div v-else>
          <input
            type="text"
            name="internaldomain"
            id="internaldomain"
            autocomplete="off"
            disabled
            placeholder="loading..."
            aria-invalid="true"
            aria-describedby="internaldomain"
            class="mt-1 relative rounded-md shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-gray-100 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
          />
        </div>
      </div>

      <div class="settings__section--footer">
        <SwitchGroup>
          <div class="settings__switch__wrapper">
            <Switch
              v-model="isOfflineModeEnabled"
              :disabled="isWaiting"
              :class="[
                isOfflineModeEnabled ? 'bg-blue-600' : 'bg-gray-300',
                'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
              ]"
            >
              <span :class="[isOfflineModeEnabled ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
            </Switch>
            <SwitchLabel class="settings__label">Send unscanned emails on poor internet connections</SwitchLabel>
            <MultilineTooltip
              :messages="[
                'Preava Prevent endpoint protection software automatically monitors user’s internet connection quality. When the connection quality to Preava servers is poor, users can send unscanned emails without being notified. Once connection quality improves, Preava Prevent will automatically resume scanning emails for risks.',
                'Enable this setting when your organization needs to maintain the ability to send emails when users are on internet connections of poor quality (e.g. while working from remote areas).'
              ]"
            />
          </div>
        </SwitchGroup>

        <p class="text-sm font-normal text-gray-600 w-full my-2 mt-2">
          Warning: Disabling this setting will prevent users from sending any emails when their internet connection quality is poor.
        </p>
      </div>

      <!-- Send anyway -->
      <div class="settings__section--footer">
        <SwitchGroup>
          <div class="settings__switch__wrapper">
            <Switch
              v-model="isSendAnywaysEnabled"
              :disabled="isWaiting"
              :class="[
                isSendAnywaysEnabled ? 'bg-blue-600' : 'bg-gray-300',
                'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
              ]"
            >
              <span :class="[isSendAnywaysEnabled ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
            </Switch>
            <SwitchLabel class="settings__label">Send unscanned emails after timeout</SwitchLabel>
            <MultilineTooltip
              :messages="[
                'Preava Prevent endpoint protection software automatically monitors the response time of our preflight API, which is responsible for scanning emails for risks at the endpoint. When the response time exceeds the set timeout period, users can send unscanned emails without being notified.',
                'Enable this setting when your organization needs to maintain the ability to send emails when temporary API or internet connection interruptions occur.'
              ]"
            />
          </div>
        </SwitchGroup>

        <div class="flex flex-col space-y-2 w-full mt-2">
          <label for="steps-range" class="settings__label--footer">
            Timeout period:
            <span v-if="sendAnywaysTimeoutMs !== undefined">{{ new Intl.NumberFormat("en-US").format(sendAnywaysTimeoutMs / 1000) }} seconds </span></label
          >
          <input
            type="range"
            min="1000"
            max="5000"
            step="250"
            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer disabled:cursor-default range-lg dark:bg-gray-700"
            :disabled="!isSendAnywaysEnabled || isWaiting"
            v-model="sendAnywaysTimeoutMs"
          />
          <div class="flex justify-between w-full px-[10px]">
            <span class="text-sm font-normal text-gray-600">{{ new Intl.NumberFormat("en-US").format(1) }}s</span>
            <span class="text-sm font-normal text-gray-600">{{ new Intl.NumberFormat("en-US").format(2) }}s</span>
            <span class="text-sm font-normal text-gray-600">{{ new Intl.NumberFormat("en-US").format(3) }}s</span>
            <span class="text-sm font-normal text-gray-600">{{ new Intl.NumberFormat("en-US").format(4) }}s</span>
            <span class="text-sm font-normal text-gray-600">{{ new Intl.NumberFormat("en-US").format(5) }}s</span>
          </div>
        </div>
        <p class="text-sm font-normal text-gray-600 w-full my-2 mt-2">
          Warning: Disabling this setting will prevent users from sending any emails when Preava servers are unreachable.
        </p>
      </div>

      <!-- Preava Prevent Footer -->
      <div class="settings__section--footer">
        <SwitchGroup>
          <div class="settings__switch__wrapper">
            <!--
                [NOTE]:
                The long list of classes found for Switch can't be moved to settings.css.
                It needs to be inlined otherwise it will cause a bug with the switch not
                properly working. Please keep the Switch classes here.
                - by @johnnypreavacom
              -->
            <Switch
              v-model="isFooterEnabled"
              :disabled="isWaiting"
              :class="[
                isFooterEnabled ? 'bg-blue-600' : 'bg-gray-300',
                'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
              ]"
            >
              <span :class="[isFooterEnabled ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
            </Switch>
            <SwitchLabel class="settings__label"> Append email footer text </SwitchLabel>
            <DefaultTooltip message="Add custom email footer text to all emails scanned by Preava Prevent." />
          </div>
        </SwitchGroup>

        <!-- Footer Text -->
        <label for="footer-text" class="settings__label--footer"> Email footer text </label>
        <input
          type="text"
          name="footer-text"
          id="footer-text"
          :disabled="!isFooterEnabled || isWaiting"
          v-model="footerContent"
          autocomplete="off"
          class="mt-1 relative rounded-md shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-white focus:outline-none focus:border-gray-500 focus:ring-gray-500 sm:text-sm rounded-md-footer"
        />
        <p class="text-sm font-normal text-gray-600 w-full my-2">
          Note: Due to a Microsoft limitation, the email footer text may not be appended to emails sent from Outlook for Mac.
        </p>
      </div>

      <!-- Preava Prevent Recipients -->
      <div class="settings__section--footer flex flex-col space-y-4">
        <SwitchGroup>
          <div class="settings__switch__wrapper">
            <Switch
              v-model="recipientsEnabled"
              :disabled="true"
              :class="[
              'bg-gray-300',
                'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
              ]"
            >
              <span :class="[recipientsEnabled ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
            </Switch>
            <SwitchLabel class="settings__label"> Process recipients </SwitchLabel>
            <DefaultTooltip message="Whether or not to process email recipients" />
          </div>
        </SwitchGroup>

        <div v-if="recipientsEnabled" class="flex flex-col space-y-3 ml-2">
          <SwitchGroup>
            <div class="settings__switch__wrapper">
              <Switch
                v-model="recipientsProcessTo"
                :disabled="isWaiting || !recipientsEnabled"
                :class="[
                  recipientsProcessTo ? 'bg-blue-600' : 'bg-gray-300',
                  'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
                ]"
              >
                <span :class="[recipientsProcessTo ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
              </Switch>
              <SwitchLabel class="settings__label"> Process To </SwitchLabel>
              <DefaultTooltip message="Whether or not to process email To recipients" />
            </div>
          </SwitchGroup>

          <SwitchGroup>
            <div class="settings__switch__wrapper">
              <Switch
                v-model="recipientsProcessBcc"
                :disabled="isWaiting || !recipientsEnabled"
                :class="[
                  recipientsProcessBcc ? 'bg-blue-600' : 'bg-gray-300',
                  'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
                ]"
              >
                <span :class="[recipientsProcessBcc ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
              </Switch>
              <SwitchLabel class="settings__label"> Process Bcc </SwitchLabel>
              <DefaultTooltip message="Whether or not to process email Bcc recipients" />
            </div>
          </SwitchGroup>

          <SwitchGroup>
            <div class="settings__switch__wrapper">
              <Switch
                v-model="recipientsProcessCc"
                :disabled="isWaiting || !recipientsEnabled"
                :class="[
                  recipientsProcessCc ? 'bg-blue-600' : 'bg-gray-300',
                  'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
                ]"
              >
                <span :class="[recipientsProcessCc ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
              </Switch>
              <SwitchLabel class="settings__label"> Process Cc </SwitchLabel>
              <DefaultTooltip message="Whether or not to process email Cc recipients" />
            </div>
          </SwitchGroup>

          <SwitchGroup>
            <div class="settings__switch__wrapper">
              <Switch
                v-model="recipientsEnableApproveAll"
                :disabled="isWaiting || !recipientsEnabled"
                :class="[
                  recipientsEnableApproveAll ? 'bg-blue-600' : 'bg-gray-300',
                  'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
                ]"
              >
                <span :class="[recipientsEnableApproveAll ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
              </Switch>
              <SwitchLabel class="settings__label"> Enable approve all </SwitchLabel>
              <DefaultTooltip message="Whether or not to enable approve all" />
            </div>
          </SwitchGroup>

          <SwitchGroup>
            <div class="settings__switch__wrapper">
              <Switch
                v-model="recipientsEnableRememberThread"
                :disabled="isWaiting || !recipientsEnabled"
                :class="[
                  recipientsEnableRememberThread ? 'bg-blue-600' : 'bg-gray-300',
                  'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
                ]"
              >
                <span :class="[recipientsEnableRememberThread ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
              </Switch>
              <SwitchLabel class="settings__label"> Enable remember thread </SwitchLabel>
              <DefaultTooltip message="Whether or not to enable remember thread" />
            </div>
          </SwitchGroup>
        </div>

        <p class="text-sm font-normal text-gray-600 w-full my-2">
          Warning: Disabling recipient processing may limit the email scanning functionality.
        </p>
      </div>
    
      <!-- Preava Prevent Content -->
      <div class="settings__section--footer flex flex-col space-y-4">
        <SwitchGroup>
          <div class="settings__switch__wrapper">
            <Switch
              v-model="contentEnabled"
              :disabled="isWaiting"
              :class="[
                contentEnabled ? 'bg-blue-600' : 'bg-gray-300',
                'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
              ]"
            >
              <span :class="[contentEnabled ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
            </Switch>
            <SwitchLabel class="settings__label"> Process content </SwitchLabel>
            <DefaultTooltip message="Whether or not to process email content" />
          </div>
        </SwitchGroup>

        <div v-if="contentEnabled" class="flex flex-col space-y-3 ml-2">
          <SwitchGroup>
            <div class="settings__switch__wrapper">
              <Switch
                v-model="contentProcessSubject"
                :disabled="isWaiting || !contentEnabled"
                :class="[
                  contentProcessSubject ? 'bg-blue-600' : 'bg-gray-300',
                  'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
                ]"
              >
                <span :class="[contentProcessSubject ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
              </Switch>
              <SwitchLabel class="settings__label"> Process Subject </SwitchLabel>
              <DefaultTooltip message="Whether or not to process email subject" />
            </div>
          </SwitchGroup>

          <SwitchGroup>
            <div class="settings__switch__wrapper">
              <Switch
                v-model="contentProcessBody"
                :disabled="isWaiting || !contentEnabled"
                :class="[
                  contentProcessBody ? 'bg-blue-600' : 'bg-gray-300',
                  'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
                ]"
              >
                <span :class="[contentProcessBody ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
              </Switch>
              <SwitchLabel class="settings__label"> Process Body </SwitchLabel>
              <DefaultTooltip message="Whether or not to process email body" />
            </div>
          </SwitchGroup>

          <SwitchGroup>
            <div class="settings__switch__wrapper">
              <Switch
                v-model="contentProcessFilenames"
                :disabled="isWaiting || !contentEnabled"
                :class="[
                  contentProcessFilenames ? 'bg-blue-600' : 'bg-gray-300',
                  'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
                ]"
              >
                <span :class="[contentProcessFilenames ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
              </Switch>
              <SwitchLabel class="settings__label"> Process File Names </SwitchLabel>
              <DefaultTooltip message="Whether or not to process email file names" />
            </div>
          </SwitchGroup>

          <SwitchGroup>
            <div class="settings__switch__wrapper">
              <Switch
                v-model="contentEnableApproveAll"
                :disabled="isWaiting || !contentEnabled"
                :class="[
                  contentEnableApproveAll ? 'bg-blue-600' : 'bg-gray-300',
                  'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
                ]"
              >
                <span :class="[contentEnableApproveAll ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
              </Switch>
              <SwitchLabel class="settings__label"> Enable approve all </SwitchLabel>
              <DefaultTooltip message="Whether or not to enable approve all" />
            </div>
          </SwitchGroup>

          <SwitchGroup>
            <div class="settings__switch__wrapper">
              <Switch
                v-model="contentEnableRememberThread"
                :disabled="isWaiting || !contentEnabled"
                :class="[
                  contentEnableRememberThread ? 'bg-blue-600' : 'bg-gray-300',
                  'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
                ]"
              >
                <span :class="[contentEnableRememberThread ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
              </Switch>
              <SwitchLabel class="settings__label"> Enable remember thread </SwitchLabel>
              <DefaultTooltip message="Whether or not to enable remember thread" />
            </div>
          </SwitchGroup>
        </div>

        <p class="text-sm font-normal text-gray-600 w-full my-2">
          Warning: Disabling content processing may diminish the effectiveness of email scanning functionality.
        </p>
      </div>

      <!-- Preava Prevent Telemetry -->
      <div class="settings__section--footer flex flex-col space-y-4">
        <SwitchGroup>
          <div class="settings__switch__wrapper">
            <Switch
              v-model="telemetryEnabled"
              :disabled="isWaiting"
              :class="[
                telemetryEnabled ? 'bg-blue-600' : 'bg-gray-300',
                'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
              ]"
            >
              <span :class="[telemetryEnabled ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
            </Switch>
            <SwitchLabel class="settings__label"> Enable telemetry </SwitchLabel>
            <DefaultTooltip message="Whether or not to enable telemetry" />
          </div>
        </SwitchGroup>

        <div v-if="telemetryEnabled" class="flex flex-col space-y-3 ml-2">
          <SwitchGroup>
            <div class="settings__switch__wrapper">
              <Switch
                v-model="telemetryEnablePiiTagging"
                :disabled="isWaiting || !telemetryEnabled"
                :class="[
                  telemetryEnablePiiTagging ? 'bg-blue-600' : 'bg-gray-300',
                  'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
                ]"
              >
                <span :class="[telemetryEnablePiiTagging ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
              </Switch>
              <SwitchLabel class="settings__label"> Enable PII tagging </SwitchLabel>
              <DefaultTooltip message="Whether or not to enable PII tagging" />
            </div>
          </SwitchGroup>
        </div>

        <p class="text-sm font-normal text-gray-600 w-full my-2">
          Warning: Disabling telemetry will prevent Preava Engineering from providing detailed debugging support. PII tagging labels telemetry traces with metadata from the email message being scanned, including fields like Subject, To, Cc, and Bcc. Disabling PII tagging will reduce the effectiveness of customer support actions.
        </p>
        </div>

      <!-- Preava Prevent Tweaks -->
      <div class="settings__section--footer flex flex-col space-y-4">
        <SwitchGroup>
          <div class="settings__switch__wrapper">
            <Switch
              v-model="tweaksIgnoreInlineImages"
              :disabled="isWaiting"
              :class="[
                tweaksIgnoreInlineImages ? 'bg-blue-600' : 'bg-gray-300',
                'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
              ]"
            >
              <span :class="[tweaksIgnoreInlineImages ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
            </Switch>
            <SwitchLabel class="settings__label"> Ignore inline images </SwitchLabel>
            <DefaultTooltip message="Whether or not ignore inline images" />
          </div>
        </SwitchGroup>

        <SwitchGroup>
          <div class="settings__switch__wrapper">
            <Switch
              v-model="tweaksIgnoreMessageThread"
              :disabled="isWaiting"
              :class="[
                tweaksIgnoreMessageThread ? 'bg-blue-600' : 'bg-gray-300',
                'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
              ]"
            >
              <span :class="[tweaksIgnoreMessageThread ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
            </Switch>
            <SwitchLabel class="settings__label"> Ignore message thread </SwitchLabel>
            <DefaultTooltip message="Whether or not to ignore message thread" />
          </div>
        </SwitchGroup>

        <SwitchGroup>
          <div class="settings__switch__wrapper">
            <Switch
              v-model="tweaksIgnoreSignature"
              :disabled="isWaiting"
              :class="[
                tweaksIgnoreSignature ? 'bg-blue-600' : 'bg-gray-300',
                'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
              ]"
            >
              <span :class="[tweaksIgnoreSignature ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
            </Switch>
            <SwitchLabel class="settings__label"> Ignore signature </SwitchLabel>
            <DefaultTooltip message="Whether or not to ignore signature" />
          </div>
        </SwitchGroup>

        <p class="text-sm font-normal text-gray-600 w-full my-2">
          Note: Please contact Customer Support to determine which of these settings should be enabled for your organization.
        </p>
      </div>

      <div class="settings__section--footer flex flex-col space-y-4">
        <div class="flex flex-col space-y-1">
          <div class="flex space-x-2">
            <label for="filter-to" class="text-sm font-bold text-gray-700"> Filter To </label>
            <DefaultTooltip message="This regular expression will be used to filter the To recipients." />
          </div>
          <input
            type="text"
            name="filter-to"
            id="filter-to"
            :disabled="isWaiting"
            v-model="filterTo"
            autocomplete="off"
            class="relative rounded-md shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-white focus:outline-none focus:border-gray-500 focus:ring-gray-500 sm:text-sm rounded-md-footer"
          />
        </div>
        <div class="flex flex-col space-y-1">
          <div class="flex space-x-2">
            <label for="filter-cc" class="text-sm font-bold text-gray-700"> Filter Cc </label>
            <DefaultTooltip message="This regular expression will be used to filter the Cc recipients." />
          </div>
          <input
            type="text"
            name="filter-cc"
            id="filter-cc"
            :disabled="isWaiting"
            v-model="filterCc"
            autocomplete="off"
            class="relative rounded-md shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-white focus:outline-none focus:border-gray-500 focus:ring-gray-500 sm:text-sm rounded-md-footer"
          />
        </div>
        <div class="flex flex-col space-y-1">
          <div class="flex space-x-2">
            <label for="filter-bcc" class="text-sm font-bold text-gray-700"> Filter Bcc </label>
            <DefaultTooltip message="This regular expression will be used to filter the Bcc recipients." />
          </div>
          <input
            type="text"
            name="filter-bcc"
            id="filter-bcc"
            :disabled="isWaiting"
            v-model="filterBcc"
            autocomplete="off"
            class="relative rounded-md shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-white focus:outline-none focus:border-gray-500 focus:ring-gray-500 sm:text-sm rounded-md-footer"
          />
        </div>
        <div class="flex flex-col space-y-1">
          <div class="flex space-x-2">
            <label for="filter-subject" class="text-sm font-bold text-gray-700"> Filter Subject </label>
            <DefaultTooltip message="This regular expression will be used to filter the email subject." />
          </div>
          <input
            type="text"
            name="filter-subject"
            id="filter-subject"
            :disabled="isWaiting"
            v-model="filterSubject"
            autocomplete="off"
            class="relative rounded-md shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-white focus:outline-none focus:border-gray-500 focus:ring-gray-500 sm:text-sm rounded-md-footer"
          />
        </div>
        <div class="flex flex-col space-y-1">
          <div class="flex space-x-2">
            <label for="filter-body" class="text-sm font-bold text-gray-700"> Filter Body </label>
            <DefaultTooltip message="This regular expression will be used to filter the email body." />
          </div>
          <input
            type="text"
            name="filter-body"
            id="filter-body"
            :disabled="isWaiting"
            v-model="filterBody"
            autocomplete="off"
            class="relative rounded-md shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-white focus:outline-none focus:border-gray-500 focus:ring-gray-500 sm:text-sm rounded-md-footer"
          />
        </div>
        <div class="flex flex-col space-y-1">
          <div class="flex space-x-2">
            <label for="filter-filenames" class="text-sm font-bold text-gray-700"> Filter Filenames </label>
            <DefaultTooltip message="This regular expression will be used to filter the email filenames." />
          </div>
          <input
            type="text"
            name="filter-filenames"
            id="filter-filenames"
            :disabled="isWaiting"
            v-model="filterFilenames"
            autocomplete="off"
            class="relative rounded-md shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-white focus:outline-none focus:border-gray-500 focus:ring-gray-500 sm:text-sm rounded-md-footer"
          />
        </div>

        <p class="text-sm font-normal text-gray-600 w-full my-2">
          Warning: These are RegEx (Regular Expression) filters applied to the email fields For further assistance, kindly contact Customer Support.
        </p>
       </div>
    </div>

    <button :disabled="isWaiting || (isSendAnywaysEnabled && isInvalidTimeout)" @click="saveSettings()" class="settings__button">
      {{ !isWaiting ? "Save" : "Please wait..." }}
    </button>

    <ToastNotification :toast="toast" />

    <DefaultNotification :notification="notification" />
  </div>
</template>
<script>
// Components
import ToastNotification from "@/components/notification/ToastNotification";
import DefaultNotification from "@/components/notification/DefaultNotification";
import DefaultHeader from "@/components/header/DefaultHeader";
import DefaultSidebar from "@/components/sidebar/DefaultSidebar";
import DefaultTooltip from "@/components/tooltip/DefaultTooltip";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";

// Adapter and User classes, etc.
import { Adapter } from "@/utilities/adapter";
import enums from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import { grpcErrorToString } from "@/utilities/helpers";
import { v4 } from "uuid";
import MultilineTooltip from "../../components/tooltip/MultilineTooltip.vue";

const ADAPTER = new Adapter();
const ENUMS = enums;
const NAME = "Settings";

export default {
	name: NAME,
	site_title: NAME,
	components: {
		// Components
		DefaultHeader,
		DefaultSidebar,
		ToastNotification,
		DefaultNotification,
		DefaultTooltip,
		Switch,
		SwitchGroup,
		SwitchLabel,
		ExclamationCircleIcon,
		MultilineTooltip,
	},
	data() {
		return {
			name: NAME,
			// UI Settings
			isAccessible: false, // for admin check
			isWaiting: false,
			isInvalidTimeout: false,

			isPreventEnabled: false,
			isFooterEnabled: false,
			footerContent: "",
			isOfflineModeEnabled: false,
			isSendAnywaysEnabled: false,
			sendAnywaysTimeoutMs: 5000,

			recipientsEnabled: false,
			recipientsProcessTo: false,
			recipientsProcessCc: false,
			recipientsProcessBcc: false,
			recipientsEnableApproveAll: false,
			recipientsEnableRememberThread: false,

			contentEnabled: false,
			contentProcessSubject: false,
			contentProcessBody: false,
			contentProcessFilenames: false,
			contentProcessAttachments: false,
			contentEnableApproveAll: false,
			contentEnableRememberThread: false,

			telemetryEnabled: false,
			telemetryEnablePiiTagging: false,

			tweaksIgnoreInlineImages: false,
			tweaksIgnoreMessageThread: false,
			tweaksIgnoreSignature: false,

      filterTo: "",
      filterCc: "",
      filterBcc: "",
      filterSubject: "",
      filterBody: "",
      filterFilenames: "",
      filterAttachments: "",

			traceId: null,
			// settings object
			settings: {},
			// tenant object
			tenant: {},
			// modified settings object
			modified_settings: {},
			// notification object
			notification: {
				isShown: false,
				title: "",
				details: "",
			},
			// toast object
			toast: {
				isShown: false,
				message: "",
			},
		};
	},

	methods: {
    getUiSettings() {
      return {
        // if there is something we need to keep from the original settings object, we can spread it here
        ...this.settings,

        protectionLevel: this.isPreventEnabled ? ENUMS.Protection.PROTECTION_PREFLIGHT : ENUMS.Protection.PROTECTION_DISABLED,
        footerStatus: this.isFooterEnabled ? ENUMS.Status.STATUS_ENABLED : ENUMS.Status.STATUS_DISABLED,
        footerContent: { value: this.footerContent },
        offlineModeStatus: this.isOfflineModeEnabled ? ENUMS.Status.STATUS_ENABLED : ENUMS.Status.STATUS_DISABLED,
        sendAnywaysStatus: this.isSendAnywaysEnabled ? ENUMS.Status.STATUS_ENABLED : ENUMS.Status.STATUS_DISABLED,
        sendAnywaysTimeoutMs: Number.parseInt(this.sendAnywaysTimeoutMs),

        recipientsEnabled: this.recipientsEnabled,
        recipientsProcessTo: this.recipientsProcessTo,
        recipientsProcessCc: this.recipientsProcessCc,
        recipientsProcessBcc: this.recipientsProcessBcc,
        recipientsEnableApproveAll: this.recipientsEnableApproveAll,
        recipientsEnableRememberThread: this.recipientsEnableRememberThread,

        contentEnabled: this.contentEnabled,
        contentProcessSubject: this.contentProcessSubject,
        contentProcessBody: this.contentProcessBody,
        contentProcessFilenames: this.contentProcessFilenames,
        contentProcessAttachments: this.contentProcessAttachments,
        contentEnableApproveAll: this.contentEnableApproveAll,
        contentEnableRememberThread: this.contentEnableRememberThread,

        telemetryEnabled: this.telemetryEnabled,
        telemetryEnablePiiTagging: this.telemetryEnablePiiTagging,

        tweaksIgnoreInlineImages: this.tweaksIgnoreInlineImages,
        tweaksIgnoreMessageThread: this.tweaksIgnoreMessageThread,
        tweaksIgnoreSignature: this.tweaksIgnoreSignature,

        filterTo: this.filterTo,
        filterCc: this.filterCc,
        filterBcc: this.filterBcc,
        filterSubject: this.filterSubject,
        filterBody: this.filterBody,
        filterFilenames: this.filterFilenames,
        filterAttachments: this.filterAttachments,
      }
    },

    setUiSettings(settings) {
      this.isPreventEnabled = settings.protectionLevel === ENUMS.Protection.PROTECTION_PREFLIGHT;
      this.isFooterEnabled = settings.footerStatus === ENUMS.Status.STATUS_ENABLED;
      this.footerContent = settings.footerContent.value;
      this.isOfflineModeEnabled = settings.offlineModeStatus === ENUMS.Status.STATUS_ENABLED;
      this.isSendAnywaysEnabled = settings.sendAnywaysStatus === ENUMS.Status.STATUS_ENABLED;
      this.sendAnywaysTimeoutMs = settings.sendAnywaysTimeoutMs;

      this.recipientsEnabled = settings.recipientsEnabled;
      this.recipientsProcessTo = settings.recipientsProcessTo;
      this.recipientsProcessCc = settings.recipientsProcessCc;
      this.recipientsProcessBcc = settings.recipientsProcessBcc;
      this.recipientsEnableApproveAll = settings.recipientsEnableApproveAll;
      this.recipientsEnableRememberThread = settings.recipientsEnableRememberThread;

      this.contentEnabled = settings.contentEnabled;
      this.contentProcessSubject = settings.contentProcessSubject;
      this.contentProcessBody = settings.contentProcessBody;
      this.contentProcessFilenames = settings.contentProcessFilenames;
      this.contentProcessAttachments = settings.contentProcessAttachments;
      this.contentEnableApproveAll = settings.contentEnableApproveAll;
      this.contentEnableRememberThread = settings.contentEnableRememberThread;

      this.telemetryEnabled = settings.telemetryEnabled;
      this.telemetryEnablePiiTagging = settings.telemetryEnablePiiTagging;

      this.tweaksIgnoreInlineImages = settings.tweaksIgnoreInlineImages;
      this.tweaksIgnoreMessageThread = settings.tweaksIgnoreMessageThread;
      this.tweaksIgnoreSignature = settings.tweaksIgnoreSignature;

      this.filterTo = settings.filterTo;
      this.filterCc = settings.filterCc;
      this.filterBcc = settings.filterBcc;
      this.filterSubject = settings.filterSubject;
      this.filterBody = settings.filterBody;
      this.filterFilenames = settings.filterFilenames;
      this.filterAttachments = settings.filterAttachments;
    },

		async fetchSettings() {
        this.isWaiting = true;
        this.toast = {
          isShown: true,
          message: "Fetching settings...",
        };

        try {
          const result = await ADAPTER.getSettings(this.traceId);
          this.settings = result;
          this.setUiSettings(result);
        } catch(err){
          this.notification = {
              type: "error",
              title: "An Error Occurred",
              details: `${err.message}. [${grpcErrorToString(err.code)}].`,
              isShown: true,
          };
        } finally {
          this.isWaiting = false;
          this.toast.isShown = false;
        }
    },

		async saveSettings() {
			this.isWaiting = true;
			this.toast = {
				isShown: true,
				toastmessage: "Updating settings...",
			};

      const newSettings = this.getUiSettings();
      try {
        const result = await ADAPTER.setSettings(this.traceId, newSettings);
        this.setUiSettings(result);
      } catch(err){
        this.setUiSettings(this.settings);
        this.notification = {
            type: "error",
            title: "An Error Occurred",
            details: `${err.message}. [${grpcErrorToString(err.code)}].`,
            isShown: true,
        };
      } finally {
        this.isWaiting = false;
        this.toast.isShown = false;
      }
		},

		// 📡 COMMS
		// Fetch the tenant object from the backend
		async getTenant() {
			return new Promise(async (resolve, reject) => {
				this.isWaiting = true;

				this.toast = {
					isShown: true,
					message: "Fetching tenant...",
				};

				ADAPTER.getTenant(this.traceId)
					.then((tenant_obj) => {
						console.log("💢");
						console.log(tenant_obj);
						this.tenant = tenant_obj;
					})
					.catch((err) => {
						// show error notification
						this.notification = {
							type: "error",
							title: "An Error Occurred",
							details: `${err.message}. [${grpcErrorToString(err.code)}].`,
							isShown: true,
						};
						reject();
					})
					.finally(() => {
						this.isWaiting = false;
						this.toast.isShown = false;
						resolve();
					});
			});
		},

		/**
		 * ✅ This method will do an admin check before the user can access the page.
		 * 📡 Will communicate with backend using adapter.
		 */
		async adminGuard() {
			return new Promise(async (resolve, reject) => {
				ADAPTER.activate(this.traceId).then(async (result) => {
					this.buttonText = result.bottle.message.value;
					if (result.role === ENUMS.Role.ROLE_ADMIN) {
						// If admin, proceed normally
						console.log(
							"%cYou have the right to view this page.",
							"color: #B4D455",
						);
						this.isAccessible = true;
						resolve();
					} else if (result.role === ENUMS.Role.ROLE_USER) {
						// Else if user, redirect immediately to /endpoints page
						console.log(
							"%cYou don't have the right to view this page. Redirecting...",
							"color: #FE2020",
						);
						this.$router.push({ path: "/integrations" });
					}
				});
			});
		},
	},

	async created() {
		/**
		 * Each Activate, we have to set the trace ID per page.
		 * This will be referenced later by other requests.
		 */
		this.traceId = v4();

		await this.adminGuard();
		await this.getTenant();
		await this.fetchSettings();
		console.log("Settings Page Initialized");
	},
};
</script>
<style scoped>
@import "./settings.css";
</style>
