<template>
  <div @mouseover="hover = true" @mouseleave="hover = false" class="tooltip__container">
    <QuestionMarkCircleIcon class="tooltip__icon" />
    <div v-if="hover" class="tooltip__wrapper">
      <div class="tooltip__message flex flex-col gap-4">
        <span v-for="(msg, index) in messages" :key="index">{{ msg }}</span>
      </div>
      <div class="tooltip__arrow"></div>
    </div>
  </div>
</template>

<script>
import { QuestionMarkCircleIcon } from "@heroicons/vue/solid";

export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
  components: {
    QuestionMarkCircleIcon,
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>

<style scoped>
@import "./tooltip.css";
</style>
