<template>
  <div class="hidden lg:block mt-8 mb-12 max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
    <header class="flex justify-between -mt-2">
      <div class="w-full">
        <h1 class="sr-only">System information</h1>
        <h1 class="text-2xl leading-7 tracking-tight font-semibold text-gray-800 sm:text-2xl sm:truncate">System information</h1>
        <p class="text-sm font-normal text-gray-600 w-full my-2">
          View system information specific to your account.
        </p>
      </div>
    </header>

    <div class="w-full lg:w-1/2 mt-8">
      <div class="mt-4" v-for="(value, index) in Object.entries(tenant)" :key="index">
        <label class="text-sm font-bold text-gray-700"> {{ tenantKeyToName[value[0]] }} </label>
        <div v-if="tenant[value[0]]">
          <input
            v-model="tenant[value[0]]"
            type="text"
            :name="`${value[0]}`"
            :id="`${value[0]}`"
            autocomplete="off"
            disabled
            :aria-invalid="tenant[value[0]] ? 'true' : 'false'"
            :aria-describedby="`${value[0]}`"
            class="mt-2 relative shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-gray-100 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
          />
        </div>
        <div v-else>
          <input
            type="text"
            :name="`${value[0]}`"
            :id="`${value[0]}`"
            autocomplete="off"
            disabled
            placeholder="loading..."
            :aria-invalid="tenant[value[0]] ? 'true' : 'false'"
            :aria-describedby="`${value[0]}`"
            class="mt-1 relative rounded-md shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-gray-100 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
          />
        </div>
      </div>
    </div>
    <ToastNotification :toast="toast" />

    <DefaultNotification :notification="notification" />
  </div>
</template>
<script>
// Components
import ToastNotification from "@/components/notification/ToastNotification";
import DefaultNotification from "@/components/notification/DefaultNotification";
import DefaultHeader from "@/components/header/DefaultHeader";
import DefaultSidebar from "@/components/sidebar/DefaultSidebar";
import DefaultTooltip from "@/components/tooltip/DefaultTooltip";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";

// Adapter and User classes, etc.
import { Adapter } from "@/utilities/adapter";
import enums from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import { grpcErrorToString } from "@/utilities/helpers";
import { v4 } from "uuid";
import MultilineTooltip from "../../components/tooltip/MultilineTooltip.vue";

const ADAPTER = new Adapter();
const ENUMS = enums;
const NAME = "System information";

export default {
  name: NAME,
  site_title: NAME,
  components: {
    // Components
    DefaultHeader,
    DefaultSidebar,
    ToastNotification,
    DefaultNotification,
    DefaultTooltip,
    Switch,
    SwitchGroup,
    SwitchLabel,
    ExclamationCircleIcon,
    MultilineTooltip,
  },
  data() {
    return {
      name: NAME,
      // UI Settings
      isAccessible: false, // for admin check
      isWaiting: false,

      tenant: {},

      tenantKeyToName: {
        audience: "Audience",
        clusterLocation: "Cluster Location",
        clusterName: "Cluster Name",
        clusterType: "Cluster Type",
        infrastructureNetworking: "Infrastructure Networking",
        infrastructureProvider: "Infrastructure Provider",
        infrastructureRegion: "Infrastructure Region",
      },

      // notification object
      notification: {
        isShown: false,
        title: "",
        details: "",
      },
      // toast object
      toast: {
        isShown: false,
        message: "",
      },
    };
  },
  watch: {},

  methods: {
    // 📡 COMMS
    // Fetch the tenant object from the backend
    async getTenancy() {
      return new Promise(async (resolve, reject) => {
        this.isWaiting = true;

        this.toast = {
          isShown: true,
          message: "Fetching tenant...",
        };

        ADAPTER.getTenant_120(this.traceId)
          .then((tenant_obj) => {
            const result = tenant_obj.tenancy;
            console.log("💢");
            console.log(result);
            this.tenant = result;
          })
          .catch((err) => {
            // show error notification
            this.notification = {
              type: "error",
              title: "An Error Occurred",
              details: `${err.message}. [${grpcErrorToString(err.code)}].`,
              isShown: true,
            };
            reject();
          })
          .finally(() => {
            this.isWaiting = false;
            this.toast.isShown = false;
            resolve();
          });
      });
    },

    /**
     * ✅ This method will do an admin check before the user can access the page.
     * 📡 Will communicate with backend using adapter.
     */
    async adminGuard() {
      return new Promise(async (resolve, reject) => {
        ADAPTER.activate(this.traceId).then(async (result) => {
          this.buttonText = result.bottle.message.value;
          if (result.role === ENUMS.Role.ROLE_ADMIN) {
            // If admin, proceed normally
            console.log("%cYou have the right to view this page.", "color: #B4D455");
            this.isAccessible = true;
            resolve();
          } else if (result.role === ENUMS.Role.ROLE_USER) {
            // Else if user, redirect immediately to /endpoints page
            console.log("%cYou don't have the right to view this page. Redirecting...", "color: #FE2020");
            this.$router.push({ path: "/integrations" });
          }
        });
      });
    },
  },

  async created() {
    /**
     * Each Activate, we have to set the trace ID per page.
     * This will be referenced later by other requests.
     */
    this.traceId = v4();

    await this.adminGuard();
    await this.getTenancy();
  },
};
</script>
<style scoped>
@import "./tenancy.css";
</style>
